import React, { useEffect, useState } from "react";
import { base_url } from "../../basic";
import Clients from "./SubModules/Clients";
import OurBlog from "./SubModules/OurBlog";
import Testimonials from "./SubModules/Testimonials";
import { getRequest } from "../../helpers/api_helpers";

const Home = () => {
  const [pageContent, setPageContent] = useState(null);
  const [shortContent, setShortContent] = useState(null);
  const [bannerData, setBannerData] = useState
  ({
    banner_desc: "",
    banner_img: "",
  });


  const loadPagData = async () => {
    try {
      getRequest({ url: "web/get-page-data-by-slug?slug=/" }).then((pages) => {
        const js_view = JSON.parse(JSON.stringify(pages));
        if (js_view.status == 200) {
          console.log("hiiiiii");
          setPageContent(js_view.data.description);
          setShortContent(js_view.data.short_description);
          setBannerData({
            ...bannerData,
            banner_desc: js_view.data.page_slider_description,
            banner_img: js_view.data.page_image,
          });
        }
      });
    } catch (e) {
      return false;
    }
  };

  useEffect(()=>{
    loadPagData();
  },[])
  


  return (
    <div>

<div dangerouslySetInnerHTML={{ __html: shortContent }}></div>


      {/* <section id="main-banner">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
              <div class="banner-left">
                <h1>
                  Project Report For <span class="line">Bank</span>{" "}
                  <span>Loan</span>
                </h1>
                <p>Generate your report in less than 10 mins</p>
                <span class="yellow">Quick / Easy/ Hassle Free</span>
              </div>
              <div class="banner-left-report">
                <a href="#">
                  <div class="banner-report-inner">
                    <div class="w-20">
                      <img
                        src={`${base_url}/assets/img/project-icon.png`}
                        class="img-fluid"
                        width="39px"
                      />
                    </div>
                    <div class="w-80">
                      <h4>Project Report</h4>
                      <p>
                        Best for PMEGP, Mudra, CGTMSE, Standup India Loan, or
                        similar startup loan programs.
                      </p>
                    </div>
                  </div>
                </a>
                <a href="#">
                  <div class="banner-report-inner-mustard">
                    <div class="w-20">
                      <img
                        src={`${base_url}/assets/img/cma-report-icon.png`}
                        class="img-fluid"
                        width="44px"
                      />
                    </div>
                    <div class="w-80">
                      <h4>CMA Report</h4>
                      <p>
                        For expansion of business or enhancing the working
                        capital / OD limit, audited statements required.
                      </p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div class="form-top-bar">
                <div class="d-flex right-line">
                  <div class="me-3">
                    <img
                      src={`${base_url}/assets/img/users-three-Regular.png`}
                      class="img-fluid"
                      width="32px"
                    />
                  </div>
                  <div>
                    <p>98 %</p>
                    <span>Customer satisfaction</span>
                  </div>
                </div>
                <div class="d-flex">
                  <div class="me-3">
                    <img
                      src={`${base_url}/assets/img/trust-icon.png`}
                      class="img-fluid"
                      width="25px"
                    />
                  </div>
                  <div>
                    <p>1Million +</p>
                    <span>User trust finline</span>
                  </div>
                </div>
              </div>
              <div class="banner-right">
                <h2>Expert Guidance for Loan Approval</h2>
                <p>
                  Our project report experts will call you to collect all data
                  and create the full report for you.
                </p>
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Name"
                />
                <input
                  type="text"
                  class="form-control"
                  placeholder="Enter Phone No."
                />
                <button type="button" class="btn call-back">
                  Call Back
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="product-section">
        <div class="container-fluid">
          <div class="product-heading">
            <h2>
              Products that make <span>your life easy</span>
            </h2>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="report-bulider">
                <h3>
                  Report <span>Builder</span>
                </h3>
                <div class="report-bulider-card">
                  <div class="me-3">
                    <img
                      src={`${base_url}/assets/img/cma-report-yellow-icon.png`}
                      class="img-fluid"
                      width="71px"
                    />
                  </div>
                  <div>
                    <h4>CMA Report Builder</h4>
                    <p>
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </p>
                    <button type="button" class="btn build">
                      Build Project Report
                    </button>
                  </div>
                </div>
                <div class="report-bulider-card">
                  <div class="me-3">
                    <img
                      src={`${base_url}/assets/img/project-icon.png`}
                      class="img-fluid"
                      width="69px"
                    />
                  </div>
                  <div>
                    <h4>CMA Report Builder</h4>
                    <p>
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </p>
                    <button type="button" class="btn build">
                      Build Project Report
                    </button>
                  </div>
                </div>
                <div class="report-bulider-card">
                  <div class="me-3">
                    <img
                      src={`${base_url}/assets/img/cma-report-yellow-icon.png`}
                      class="img-fluid"
                      width="71px"
                    />
                  </div>
                  <div>
                    <h4>CMA Report Builder</h4>
                    <p>
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </p>
                    <button type="button" class="btn build">
                      Build Project Report
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
              <div class="business-loan">
                <h3>Business loans in 3 simple step </h3>
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="d-flex justify-content-center">
                      <div class="loan-steps">
                        <div class="loan-steps-circle br-bottom">
                          <span>Step 1</span>
                        </div>
                        <div class="loan-steps-circle br-bottom">
                          <span>Step 2</span>
                        </div>
                        <div class="loan-steps-circle">
                          <span>Step 3</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                    <div class="business-loan-card">
                      <div class="me-3">
                        <img
                          src={`${base_url}/assets/img/cma-report-yellow-icon.png`}
                          class="img-fluid"
                          width="71px"
                        />
                      </div>
                      <div>
                        <h4>CMA Report Builder</h4>
                        <p>
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </p>
                        <button type="button" class="btn build">
                          Apply For Loan
                        </button>
                      </div>
                    </div>
                    <div class="business-loan-card">
                      <div class="me-3">
                        <img
                          src={`${base_url}/assets/img/cma-report-yellow-icon.png`}
                          class="img-fluid"
                          width="71px"
                        />
                      </div>
                      <div>
                        <h4>CMA Report Builder</h4>
                        <p>
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </p>
                        <button type="button" class="btn build">
                          Apply For Loan
                        </button>
                      </div>
                    </div>
                    <div class="business-loan-card">
                      <div class="me-3">
                        <img
                          src={`${base_url}/assets/img/cma-report-yellow-icon.png`}
                          class="img-fluid"
                          width="71px"
                        />
                      </div>
                      <div>
                        <h4>CMA Report Builder</h4>
                        <p>
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </p>
                        <button type="button" class="btn build">
                          Apply For Loan
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-center">
              <p class="product-section-footer">
                Stop wasting time and money on technology.
                <span> Explore our company</span>
              </p>
            </div>
          </div>
        </div>
      </section>

      <section id="about-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="about-enterfin">
                <h2>
                  About <span>Enterfin</span>
                </h2>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries,Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries,Lorem
                  Ipsum has been the industry's standard dummy text ever since
                  the 1500s, when an unknown printer took a galley of type and
                  scrambled it to make a type specimen book. It has survived not
                  only five centuries,Lorem Ipsum has been the industry.
                </p>
              </div>
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <div class="about-card">
                    <div class="progress blue">
                      <span class="progress-left">
                        <span class="progress-bar"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar"></span>
                      </span>
                      <div class="progress-value">95%</div>
                    </div>
                    <h3>Business Solution</h3>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <div class="about-card mt-39">
                    <div class="progress blue">
                      <span class="progress-left">
                        <span class="progress-bar"></span>
                      </span>
                      <span class="progress-right">
                        <span class="progress-bar"></span>
                      </span>
                      <div class="progress-value">80%</div>
                    </div>
                    <h3>Business Growth</h3>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="about-enterfin-img">
                <img
                  src={`${base_url}/assets/img/about-enterfin.png`}
                  class="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="enterfin-everyone">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
              <div class="everyone-text">
                <h2>
                  Enterfin is for <span>everyone</span>
                </h2>
                <p>
                  Lorem Ipsum has been the industry's standard dummy text ever
                  since the 1500s, when an unknown printer took a galley of type
                  and scrambled it to make a type specimen book. It has survived
                  not only five centuries,Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled unknown printer
                  took a galley of type and scrambled it to make a type specimen
                  book. It has survived not only five centuries,Lorem Ipsum has
                  been the industry.
                </p>
                <div>
                  <img
                    src={`${base_url}/assets/img/small-customer-icon.png`}
                    class="img-fluid me-1"
                    width="100px"
                  />
                  <span>+1k Customers</span>
                </div>
                <button type="button" class="btn report">
                  Build Project Report
                </button>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 ms-auto">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                  <img
                    src={`${base_url}/assets/img/everyone-img1.png`}
                    class="img-fluid rounded"
                  />
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4" >
                <div class="everyone-card">
                  <h3>CAs & Tax Consultants</h3>
                  <p>
                    Upload other documents ( GST details, audited reports ,
                    registration proof etc)
                  </p>
                  <button type="button" class="btn create">
                    Create Report
                  </button>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                <div class="everyone-card">
                  <h3>Individuals/MSME Owners</h3>
                  <p>
                    Best for MSME owner and individuals who want to create one
                    report for there bank loan
                  </p>
                  <button type="button" class="btn create">
                    Create Report
                  </button>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mb-4">
                <img
                  src={`${base_url}/assets/img/everyone-img2.png`}
                  class="img-fluid rounded"
                />
              </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="lakh-report">
        <div class="container-fluid">
          <div class="row justify-content-center">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8 lakh-report-heading">
              <h2>
                Over <span>1 Lakh + Reports</span> Are Created By People Like
                You
              </h2>
              <p>
                See the sample report and get idea on how finline has made the
                report creation process
                <br /> cost effective and easy.
              </p>
            </div>
          </div>
        </div>
        <div class="d-flex">
          <div class="content">
            <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
              <div class="content-overlay"></div>
              <img
                class="content-image"
                src={`${base_url}/assets/img/report-img1.png`}
                alt=""
              />
              <div class="content-details fadeIn-bottom">
                <h3 class="content-title">Provision-Grocery shop</h3>
                <p class="content-text">
                  Lorem Ipsum has been the industry's standard ever since the
                  1500s, when an printer took a galley of type and scrambled.
                </p>
                <button type="button" class="btn learn">
                  Learn More
                </button>
              </div>
            </a>
          </div>
          <div class="content">
            <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
              <div class="content-overlay"></div>
              <img
                class="content-image"
                src={`${base_url}/assets/img/report-img2.png`}
                alt=""
              />
              <div class="content-details fadeIn-bottom">
                <h3 class="content-title">Provision-Grocery shop</h3>
                <p class="content-text">
                  Lorem Ipsum has been the industry's standard ever since the
                  1500s, when an printer took a galley of type and scrambled.
                </p>
                <button type="button" class="btn learn">
                  Learn More
                </button>
              </div>
            </a>
          </div>
          <div class="content">
            <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
              <div class="content-overlay"></div>
              <img
                class="content-image"
                src={`${base_url}/assets/img/report-img3.png`}
                alt=""
              />
              <div class="content-details fadeIn-bottom">
                <h3 class="content-title">Provision-Grocery shop</h3>
                <p class="content-text">
                  Lorem Ipsum has been the industry's standard ever since the
                  1500s, when an printer took a galley of type and scrambled.
                </p>
                <button type="button" class="btn learn">
                  Learn More
                </button>
              </div>
            </a>
          </div>
          <div class="content">
            <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
              <div class="content-overlay"></div>
              <img
                class="content-image"
                src={`${base_url}/assets/img/report-img4.png`}
                alt=""
              />
              <div class="content-details fadeIn-bottom">
                <h3 class="content-title">Provision-Grocery shop</h3>
                <p class="content-text">
                  Lorem Ipsum has been the industry's standard ever since the
                  1500s, when an printer took a galley of type and scrambled.
                </p>
                <button type="button" class="btn learn">
                  Learn More
                </button>
              </div>
            </a>
          </div>
          <div class="content">
            <a href="https://unsplash.com/photos/HkTMcmlMOUQ" target="_blank">
              <div class="content-overlay"></div>
              <img
                class="content-image"
                src={`${base_url}/assets/img/report-img5.png`}
                alt=""
              />
              <div class="content-details fadeIn-bottom">
                <h3 class="content-title">Provision-Grocery shop</h3>
                <p class="content-text">
                  Lorem Ipsum has been the industry's standard ever since the
                  1500s, when an printer took a galley of type and scrambled.
                </p>
                <button type="button" class="btn learn">
                  Learn More
                </button>
              </div>
            </a>
          </div>
        </div>
        <div class="text-center">
          <button type="button" class="btn btn-Report">
            View All Report
          </button>
        </div>
      </section>

      <section id="jargon-section">
         <div class="container-fluid">
            <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-7">
                  <div class="jargon-img">
                     <img src={`${base_url}/assets/img/jargon-phone.png`} class="img-fluid"/>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                  <div class="jargon-text">
                     <h2>No jargon, Always there for you</h2>
                     <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit
                        tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.No jargon, Always there for you
                     </p>
                     <div class="jargon-card">
                        <div class="wallet">
                           <img src={`${base_url}/assets/img/wallet.gif`} class="img-fluid" width="54px"/>
                        </div>
                        <ul>
                           <li>Flexible payment terms</li>
                           <li>Customized repayment schedules</li>
                           <li>No prepayment penalty</li>
                           <li>Convenient payment options</li>
                        </ul>
                     </div>
                     <div class="jargon-bottom">
                        <button type="button" class="btn btn-Report">Build Project Report</button>
                        <div>
                           <img src={`${base_url}/assets/img/small-customer-icon.png`} class="img-fluid me-2" width="100px"/>
                           <span>+1k Customers</span>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="grow-business-section">
         <div class="container-fluid">
            <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="row mr-90">
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <div class="grow-business-card">
                           <img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="71"/>
                           <h3>Planning</h3>
                           <p>Our Project report is accepted by all banks and NBFCs across India</p>
                           <button type="button" class="btn learn">Learn More</button>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-40">
                        <div class="grow-business-card">
                           <img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="71"/>
                           <h3>Support</h3>
                           <p>Our Project report is accepted by all banks and NBFCs across India</p>
                           <button type="button" class="btn learn">Learn More</button>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                        <div class="grow-business-card">
                           <img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="71"/>
                           <h3>Management</h3>
                           <p>Our Project report is accepted by all banks and NBFCs across India</p>
                           <button type="button" class="btn learn">Learn More</button>
                        </div>
                     </div>
                     <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 mt-40">
                        <div class="grow-business-card">
                           <img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="71"/>
                           <h3>Maintenance</h3>
                           <p>Our Project report is accepted by all banks and NBFCs across India</p>
                           <button type="button" class="btn learn">Learn More</button>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="grow-business-text">
                     <h2>We Provide Services To <span>Grow Your Business</span></h2>
                     <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry.</p>
                     <div class="row">
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                           <ul>
                              <li>Management</li>
                              <li>Planning</li>
                           </ul>
                        </div>
                        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5">
                           <ul>
                              <li>Support</li>
                              <li>Maintenance</li>
                           </ul>
                        </div>
                     </div>
                     <button type="button" class="btn build">Build Project Report</button>
                  </div>
               </div>
            </div>
         </div>
      </section>

      <section id="achive-section">
         <div class="container-fluid">
            <div class="achive-heading">
               <h2>What We Have <span>Achive</span></h2>
            </div>
            <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                  <div class="achive-card">
                     <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                     <div>
                        <h3>9878+</h3>
                        <p>Happy Clients</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                  <div class="achive-card">
                     <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                     <div>
                        <h3>9878+</h3>
                        <p>Loans Processed</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                  <div class="achive-card">
                     <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                     <div>
                        <h3>9878+</h3>
                        <p>Employees</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="achive-card">
                     <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                     <div>
                        <h3>9878+</h3>
                        <p>Safe & Secure</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section> */}

    <Testimonials/>

      <section id="team-section">
         <div class="container-fluid">
            <div class="team-heading">
               <h2>Here is our <span>professional team</span></h2>
            </div>
            <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-1.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Manpreet Singh</h3>
                        <p>Founder</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-2.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Rahdu M. Barrk</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-3.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Joana K. Soleir</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-4.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Marc P. Dursk</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

     <Clients/>

     <OurBlog/>

      <section id="faq-section">
         <div class="container-fluid">
            <div class="faq-heading">
               <h2>Frequently Asked <span>Questions</span></h2>
            </div>
            <div class="row align-items-center justify-content-center">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-10">
                  <div class="accordion" id="accordionExample">
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                           <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> Where is Enterfin located?
                           </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> What is a cloud-based platform?
                           </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingThree">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> Can Enterfin assist with international legal matters?
                           </button>
                        </h2>
                        <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFour">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> Are Enterfin's services customizable?
                           </button>
                        </h2>
                        <div id="collapseFour" class="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingFive">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> Is client data secure on Enterfin's platform?
                           </button>
                        </h2>
                        <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                     <div class="accordion-item">
                        <h2 class="accordion-header" id="headingSix">
                           <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                           <img src={`${base_url}/assets/img/faq-icon.png`} class="img-fluid me-3" width="51px"/> How does AI enhance legal solutions?
                           </button>
                        </h2>
                        <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix" data-bs-parent="#accordionExample">
                           <div class="accordion-body">
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.<br/>
                              Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled.
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>

    </div>
  );
};

export default Home;
