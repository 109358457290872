import React from "react";
import { base_url } from "../../../basic";


const Acheived = () => {


    return(
        <section id="achive-section">
        <div class="container-fluid">
           <div class="achive-heading">
              <h2>What We Have <span>Achive</span></h2>
           </div>
           <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                 <div class="achive-card">
                    <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                    <div>
                       <h3>9878+</h3>
                       <p>Happy Clients</p>
                    </div>
                 </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                 <div class="achive-card">
                    <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                    <div>
                       <h3>9878+</h3>
                       <p>Loans Processed</p>
                    </div>
                 </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3 br-right">
                 <div class="achive-card">
                    <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                    <div>
                       <h3>9878+</h3>
                       <p>Employees</p>
                    </div>
                 </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                 <div class="achive-card">
                    <div class="me-3"><img src={`${base_url}/assets/img/cma-report-yellow-icon.png`} class="img-fluid" width="58px"/></div>
                    <div>
                       <h3>9878+</h3>
                       <p>Safe & Secure</p>
                    </div>
                 </div>
              </div>
           </div>
        </div>
     </section>
    );
};

export default Acheived;