import { configureStore } from '@reduxjs/toolkit';
import {persistStore,persistReducer} from "redux-persist";
import storage from "redux-persist/lib/storage";
import userReducer from './userSlice';
// import pricingReducer from './pricingSlice';
// import pathReducer from './pathSlice';
const persistConfig = {
  key: "root",
  storage,
};
const persistedReducer = persistReducer(persistConfig, userReducer)
// const persistedPlanReducer = persistReducer(persistConfig, pricingReducer)
// const persistedPathReducer = persistReducer(persistConfig, pathReducer)
const store= configureStore({
  reducer: {
    user: persistedReducer,
    // plan:persistedPlanReducer,
    // path:persistedPathReducer
  },
})

export const persistor = persistStore(store);
export default store;