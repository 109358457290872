import React from "react";
import { base_url } from "../../basic";


const SignUp = () => {

    return(
<section id="login-section"> 
  <div class="container-fluid">
    <div class="login-outer">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div class="login-content">
            <a href="#"><img src={`${base_url}/assets/right-arrow-icon.png`} class="img-fluid"/> Back</a>
            <h1>Welcome to <span>Enterfin</span></h1>
            <p>Don’t have an account? <a href="sign-in.html">Sign in</a></p>
            <ul>
              <li>Build Project Report & CMA Reports online for your bank loans</li>
              <li>Secure & integrated options to create project report online in less than 10 minutes.</li>
              <li>Accepted by all leading banks in India.</li>
              <li>Unlimited edits and downloads.</li> 
            </ul>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
          <div class="login-form">
            <a href="#" class="sigup"><img src="img/google-icon.png" class="img-fluid me-2" width="33px"/> Sign Up With Google</a>
            <p class="continue">or continue with</p>
            <div class="row">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div class="consultation_input">
                  <input placeholder="" class="form-control" type="text"/>
                  <label>Full Name</label>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                <div class="consultation_input">
                  <input placeholder="" class="form-control" type="text"/>
                  <label>Last Name</label>
                </div>
              </div>
            </div>
            <div class="consultation_input">
              <input placeholder="" class="form-control" type="text"/>
              <label>Phone Number</label>
            </div>
            <div class="consultation_input">
              <input placeholder="" class="form-control" type="text"/>
              <label>Email Address</label>
            </div>
            <div class="consultation_input">
              <div>
              <input id="password-field" placeholder="" class="form-control" type="password"/>
              <label>Password</label>
            </div>
            <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"></span>
            </div>
            <div class="form-check">
              <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
              <label class="form-check-label" for="flexCheckDefault">
               I read and agree with the <a href="#">Terms of Service</a> and <a href="#">Privacy Policy</a>
             </label>
           </div>
           <button type="button" class="btn sign-up">Sign Up</button>
         </div>
       </div>
     </div>
   </div>
 </div>
</section>

    );
};

export default SignUp;