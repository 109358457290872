import React from "react";
import { base_url } from "../../basic";
import Acheived from "./SubModules/Achieved";


const ContactUs = () => {

return(
<div>
<section id="about-banner-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="about-text">
          <span class="line me-3">Contact Us </span><span>Enterfin Loan</span>
          <h1>Lorem Lpsum Has Been The <span>Industry's Standard</span></h1>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="contact-section"> 
  <div class="container">
    <div class="contact-heading">
      <h2><span>Contact Us</span> Catch Us Here</h2>
    </div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <div class="contact-us-card">
        <img src={`${base_url}/assets/img/email-icon.png`} class="img-fluid" width="119px"/>
        <h3>Email Address</h3>
        <p>xyz@gmail.com</p>
        <p>abc@gmail.com</p>
      </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <div class="contact-us-card">
        <img src={`${base_url}/assets/img/contact-call-icon.png`} class="img-fluid" width="119px"/>
        <h3>Call Address</h3>
        <p>(+91) 9870310368</p>
        <p>(+1) 6292766228</p>
      </div>
      </div>                                                                                                                                                                                                                                    
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
        <div class="contact-us-card">
        <img src={`${base_url}/assets/img/contact-location-icon.png`} class="img-fluid" width="119px"/>
        <h3>Office Address</h3>
        <p>Enterfin Inc, KPMG Building, 355 S. Grand Ave. Suite 2450 Los Angeles CA 90071, United States</p>
      </div>
      </div>
    </div>
  </div>
</section>

<section id="contact-form-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="contact-form">
          <h2>Tell us what <span>you need</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley </p>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="consultation_input mt-2">
              <input placeholder="" class="form-control" type="text"/>
              <label>Full Name</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="consultation_input mt-2">
              <input placeholder="" class="form-control" type="text"/>
              <label>Email Address</label>
            </div>
          </div>
        </div>
            <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="consultation_input mt-2">
              <input placeholder="" class="form-control" type="text"/>
              <label>Phone Number</label>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
            <div class="consultation_input mt-2">
              <input placeholder="" class="form-control" type="text"/>
              <label>Subject</label>
            </div>
          </div>
        </div>
            <div class="consultation_input mt-2">
              <textarea class="form-control" placeholder="Your Message" rows="4"></textarea>
            </div>                                            
            <button type="button" class="btn send">Send Message</button>   
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="contact-us-img">
          <img src={`${base_url}/assets/img/contact-us-img.png`} class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</section>

<Acheived/>

<section id="location-section">
  <div class="container-fluid">
    <h2>Find Us on Map <span>Locations</span></h2>
  </div>
  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.917970122078!2d77.3635836750072!3d28.602237585456653!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce89254019c2d%3A0xbaa4e826c3b1e3d3!2sEnterslice%20%7C%20Virtual%20CFO%20%7C%20BFSI%20Advisory%20%7C%20Audit%20%7C%20Risk%20%26%20Assurance%20%7C%20Legal%20Advisory!5e0!3m2!1sen!2sin!4v1708938133233!5m2!1sen!2sin" width="100%" height="450" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
</section>

</div>

);

};

export default ContactUs;