import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout, selectUser } from "../store/userSlice";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { base_url } from "../basic";
import { getRequest } from "../helpers/api_helpers";
// import { getRequest } from "../helpers/Linkpi_helpers";
// import { base_url } from "../basic";

const Header = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(selectUser);

  const [logo, setLogo] = useState("");
  const [data, setData] = useState();
  const [menu, setMenu] = useState([]);
  const [myUrl, setMyUrl] = useState('/');

  useEffect(() => {
    websitelogo();
    let pathname = window.location.pathname;
    let pathtest = pathname.split("/");
    console.log(pathtest[1],"pathtest")
    // setMyUrl(pathtest[1]);
    // websitemenu2();
    if (pathtest[1] == "profile") {
      if (!user) {
        navigate("/login");
      }
    }
    websitemenu2();
  }, []);

  const websitelogo = async () => {
    try {
      const logo = await getRequest({ url: "web/get-company-profile" });
      const weblogo = JSON.parse(JSON.stringify(logo));
      console.log(weblogo, "doodlel");
      if (weblogo.status == 200) {
        setData(weblogo.data);
        console.log(weblogo, "weblogo");
        setLogo(weblogo.data.logo);
        localStorage.setItem("logo_url", weblogo.data.logo);
      }
    } catch (e) {
      return false;
    }
  };

  console.log(user, "userrrrrr");

  const getInitials = (string) => {
    var names = string?.split(" "),
      initials = names?.[0].substring(0, 1).toUpperCase();

    if (names?.length > 1) {
      initials += names[names?.length - 1].substring(0, 1).toUpperCase();
    }
    return initials;
  };

  const websitemenu2 = async () => {
    try {
      getRequest({ url: "web/get-menu?in_menu=1" })
        .then((menuList) => {
          const webmenu = JSON.parse(JSON.stringify(menuList));
          if (webmenu.status == 200) {
            console.log(webmenu.data, "webmenu");
            let menu_item = [];

            webmenu.data.map((item) => {
              if (item.in_menu == 1) {
                menu_item.push(item);
              }
              menu_item.sort(function (a, b) {
                return a.menu_order - b.menu_order;
              });
              console.log(menu_item, "menu_item");
              setMenu(menu_item);
            });

            // setMenu(webmenu.result);
          }
        })
        .catch((e) => {
          console.log(e, "err");
        });
    } catch (e) {
      return false;
    }
  };

  console.log(menu, "menu");

  const handleLogout = () => {
    localStorage.clear();
    localStorage.removeItem("web_token");
    localStorage.removeItem("user_id");
    localStorage.removeItem("user_email");
    localStorage.removeItem("user_name");
    localStorage.removeItem("user_mobile");
    localStorage.removeItem("user_subscription_status");
    dispatch(logout());
    navigate("/login");
  };

  return (
    <>
      <div>
        <section id="top-bar">
          <div class="container-fluid">
            <div class="row">
              <div class="top-links">
                <Link href="#" class="me-4">
                  <i class="fa fa-phone"></i> {data?.mobile}
                </Link>
                <Link href="#" class="me-4">
                  <i class="fa fa-whatsapp"></i>
                  {data?.mobile_sec}
                </Link>
                <Link to={data?.linked_In_link} class="me-3">
                  <i class="fa fa-facebook"></i>
                </Link>
                <Link to={data?.twitter_link} class="me-3">
                  <i class="fa fa-twitter"></i>
                </Link>
                <Link to={data?.linked_In_link} class="me-3">
                  <i class="fa fa-linkedin"></i>
                </Link>
                <Link to={data?.gpluse_link}>
                  <i class="fa fa-youtube-play"></i>
                </Link>
              </div>
            </div>
          </div>
        </section>
        <nav class="navbar navbar-expand-lg bg-light sticky-top">
          <div class="container-fluid">
            <Link class="navbar-brand" href="index.html">
              <img src={logo} class="img-fluid" width="190px" />
            </Link>
            <button
              class="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarSupportedContent">
              <ul class="navbar-nav ms-auto">
                {menu?.map((item, idx) => (
                  <>
                    <li class="nav-item" key={item?.id}>
                      <Link
                        class={`nav-link ${(item?.slug === myUrl) ? 'active': '' }`}
                        onClick={()=>setMyUrl(item?.slug)}
                        aria-current="page"
                        to={item?.slug == '/' ? '': `/${item?.slug}`}
                      >
                        {item?.menu_name}
                      </Link>
                    </li>
                  </>
                ))}

                {/* <li class="nav-item">
                  <Link class="nav-link active" aria-current="page" to={"/"}>
                    Home
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/about-us">
                    About Us
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/pricing">
                    Pricing
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/report">
                    Reports
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/blog">
                    Blog
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/contact-us">
                    Contact Us
                  </Link>
                </li>
                <li class="nav-item">
                  <Link class="nav-link" to="/login">
                    Login
                  </Link>
                </li> */}
                <li class="nav-item">
                  <Link class="register" to="/register">
                    Register now
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
