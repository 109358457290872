import React from "react";
import Clients from "./SubModules/Clients";
import { base_url } from "../../basic";
import Acheived from "./SubModules/Achieved";
import Testimonials from "./SubModules/Testimonials";
import Faq from "./SubModules/Faq";

const Report = () => {
  return (
    <div>
      <section id="about-banner-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="about-text">
                <span class="line me-3">Report </span>
                <span>Enterfin Loan</span>
                <h1>
                  Lorem Lpsum Has Been The <span>Industry's Standard</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Clients />

      <section id="report-section">
        <div class="container-fluid">
          <div class="report-heading">
            <h2>
              Sample Project <span>Report</span>
            </h2>
            <p>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the <br />
              1500s, when an unknown printer took a galley of type and
              scrambled.
            </p>
            <div class="d-flex align-items-center justify-content-center">
              <div class="position-relative">
                <input
                  type="text"
                  class="form-control"
                  placeholder="Search here.."
                />
                <a href="#" class="search">
                  <img
                    src={`${base_url}/assets/img/search-icon.png`}
                    class="img-fluid"
                    width="11px"
                  />
                </a>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="d-flex align-items-center justify-content-center">
              <ul class="nav nav-pills" id="pills-tab" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link active"
                    id="pills-report1-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-report1"
                    type="button"
                    role="tab"
                    aria-controls="pills-report1"
                    aria-selected="true"
                  >
                    All Reports
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-report2-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-report2"
                    type="button"
                    role="tab"
                    aria-controls="pills-report2"
                    aria-selected="false"
                  >
                    Manufacturing
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-report3-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-report3"
                    type="button"
                    role="tab"
                    aria-controls="pills-report3"
                    aria-selected="false"
                  >
                    Agriculture
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-report4-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-report4"
                    type="button"
                    role="tab"
                    aria-controls="pills-report4"
                    aria-selected="false"
                  >
                    Service
                  </button>
                </li>
                <li class="nav-item" role="presentation">
                  <button
                    class="nav-link"
                    id="pills-report5-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-report5"
                    type="button"
                    role="tab"
                    aria-controls="pills-report5"
                    aria-selected="false"
                  >
                    Trading
                  </button>
                </li>
              </ul>
            </div>
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-report1"
                role="tabpanel"
                aria-labelledby="pills-report1-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-1.png`} class="img-fluid" />
                        <a href="">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-2.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-report2"
                role="tabpanel"
                aria-labelledby="pills-report2-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-1.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-2.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-report3"
                role="tabpanel"
                aria-labelledby="pills-report3-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-1.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-2.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-report4"
                role="tabpanel"
                aria-labelledby="pills-report4-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-1.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>Ladies & Fancy store</h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-2.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="pills-report5"
                role="tabpanel"
                aria-labelledby="pills-report5-tab"
                tabindex="0"
              >
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-1.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-2.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                    <div class="report-card">
                      <div class="report-img">
                        <img src={`${base_url}/assets/img/report-3.png`} class="img-fluid" />
                        <a href="#">
                          <div class="img-text">
                            <h3>2024</h3>
                            <h4>
                              Project <span>Report</span>
                            </h4>
                          </div>
                        </a>
                      </div>
                      <div class="report-card-inner">
                        <h5>
                          <a href="#">Ladies & Fancy store</a>
                        </h5>
                        <p>
                          Lorem Ipsum has been the industry's standard dummy
                          text ever since the 1500s, when an unknown printer
                          took a galley of type and scrambled.
                        </p>
                        <a href="#" class="view">
                          View Detail
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-4">
              <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 m-auto custom-pagination">
                <nav aria-label="...">
                  <ul class="pagination">
                    <li class="page-item disabled">
                      <a class="page-link">
                        <i class="fa fa-angle-left"></i> Prev
                      </a>
                    </li>
                    <li class="page-item active">
                      <a class="page-link" href="#">
                        1
                      </a>
                    </li>
                    <li class="page-item" aria-current="page">
                      <a class="page-link" href="#">
                        2
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        3
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        ...
                      </a>
                    </li>
                    <li class="page-item">
                      <a class="page-link" href="#">
                        10
                      </a>
                    </li>
                    <li class="page-item next">
                      <a class="page-link" href="#">
                        Next <i class="fa fa-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Acheived/>

      <Testimonials/>

      <Faq/>
    </div>
  );
};

export default Report;
