import React from "react";
import { Routes, Route } from "react-router-dom";
import Layout from "../layout/index";
import { publicRoutes } from './routes'
const Index = () => {
  return (
    <>
    <Routes>
      <Route>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
            //   <NonAuthLayout>
                <Layout>{route.component}</Layout>
            //   </NonAuthLayout>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Route>

      {/* <Route>
          {authProtectedRoutes.map((route, idx) => (
            <Route
              path={route.path}
              element={
                <AuthProtected>
                  <Layout>{route.component}</Layout>
                </AuthProtected>
              }
              key={idx}
              exact={true}
            />
          ))}
        </Route> */}
    </Routes>
    </>
  );
};

export default Index;

