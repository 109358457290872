import React, { useEffect } from "react";
import { base_url } from "../../basic";

const Blog = () => {
  useEffect(() => {
    // getSearchParamsForLocation();
  }, []);



  return (
    <div>
      <section id="about-banner-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="about-text">
                <span class="line me-3">Blog </span>
                <span>Enterfin Loan</span>
                <h1>
                  Lorem Lpsum Has Been The <span>Industry's Standard</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="blog-page-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
                  <div class="blog-card">
                    <img
                      src={`${base_url}/assets/img/blog-img1.png`}
                      width="100%"
                      class="img-fluid"
                    />
                    <div class="blog-card-inner">
                      <span class="date">Thu, December 12</span>
                      <h3>
                        <a href="#">
                          Our Project report is accepted by all banks and NBFCs
                          across India
                        </a>
                      </h3>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <img
                            src={`${base_url}/assets/img/customer-img.png`}
                            width="50px"
                            class="img-fluid rounded-circle me-3"
                          />
                          <span>Julia Parker</span>
                        </div>
                        <div>
                          <a href="#" class="more">
                            Read More
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="report-detail-right">
                <div class="categories-panel">
                  <div class="categories-inner">
                    <h3>Categories</h3>
                    <div class="categories-main">
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                      <a href="#" class="categories-links">
                        Tax
                      </a>
                      <a href="#" class="categories-links">
                        Business
                      </a>
                      <a href="#" class="categories-links">
                        Loan
                      </a>
                    </div>
                  </div>
                  <div class="recent">
                    <h3>Recent Post’s</h3>
                    <div class="recent-post">
                      <div class="w-20">
                        <img
                          src={`${base_url}/assets/img/recent-post-1.png`}
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="w-80">
                        <h6>Julia Parker</h6>
                        <p>Repeat predefined chunks repeat predefined chunks</p>
                      </div>
                    </div>
                    <div class="recent-post">
                      <div class="w-20">
                        <img
                          src={`${base_url}/assets/img/recent-post-2.png`}
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="w-80">
                        <h6>Julia Parker</h6>
                        <p>Repeat predefined chunks repeat predefined chunks</p>
                      </div>
                    </div>
                    <div class="recent-post">
                      <div class="w-20">
                        <img
                          src={`${base_url}/assets/img/recent-post-3.png`}
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="w-80">
                        <h6>Julia Parker</h6>
                        <p>Repeat predefined chunks repeat predefined chunks</p>
                      </div>
                    </div>
                    <div class="recent-post">
                      <div class="w-20">
                        <img
                          src={`${base_url}/assets/img/recent-post-4.png`}
                          class="img-fluid rounded"
                        />
                      </div>
                      <div class="w-80">
                        <h6>Julia Parker</h6>
                        <p>Repeat predefined chunks repeat predefined chunks</p>
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="button" class="btn more">
                      Learn More
                    </button>
                  </div>
                </div>
                <div class="report-detail-comments">
                  <h3>Last Comment</h3>
                  <div class="report-detail-comments-inner">
                    <p>
                      <a href="#">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer scrambled
                        type specimen book.
                      </a>
                    </p>
                    <div class="comment-inner">
                      <img
                        src={`${base_url}/assets/img/comment-img1.png`}
                        class="img-fluid rounded-circle me-2"
                      />
                      <div>
                        <p>Julia Parker</p>
                        <p>15 April 2022</p>
                      </div>
                    </div>
                  </div>
                  <div class="report-detail-comments-inner">
                    <p>
                      <a href="#">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer scrambled
                        type specimen book.
                      </a>
                    </p>
                    <div class="comment-inner">
                      <img
                        src={`${base_url}/assets/img/comment-img2.png`}
                        class="img-fluid rounded-circle me-2"
                      />
                      <div>
                        <p>Julia Parker</p>
                        <p>15 April 2022</p>
                      </div>
                    </div>
                  </div>
                  <div class="report-detail-comments-inner">
                    <p>
                      <a href="#">
                        Lorem Ipsum has been the industry's standard dummy text
                        ever since the 1500s, when an unknown printer scrambled
                        type specimen book.
                      </a>
                    </p>
                    <div class="comment-inner">
                      <img
                        src={`${base_url}/assets/img/comment-img1.png`}
                        class="img-fluid rounded-circle me-2"
                      />
                      <div>
                        <p>Julia Parker</p>
                        <p>15 April 2022</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="report-detail-card mt-4">
                  <img
                    src={`${base_url}/assets/img/logo-black.png`}
                    class="img-fluid"
                    width="190px"
                  />
                  <p>
                    {" "}
                    Lorem Ipsum is not simply random text. It has roots in a
                    piece of classical Latin literature from 45 BC
                  </p>
                  <button type="button" class="btn report">
                    Build Project Report
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="blog-card">
                <img
                  src={`${base_url}/assets/img/blog-img1.png`}
                  width="100%"
                  class="img-fluid"
                />
                <div class="blog-card-inner">
                  <span class="date">Thu, December 12</span>
                  <h3>
                    <a href="#">
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </a>
                  </h3>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <img
                        src={`${base_url}/assets/img/customer-img.png`}
                        width="50px"
                        class="img-fluid rounded-circle me-3"
                      />
                      <span>Julia Parker</span>
                    </div>
                    <div>
                      <a href="#" class="more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="blog-card">
                <img
                  src={`${base_url}/assets/img/blog-img1.png`}
                  width="100%"
                  class="img-fluid"
                />
                <div class="blog-card-inner">
                  <span class="date">Thu, December 12</span>
                  <h3>
                    <a href="#">
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </a>
                  </h3>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <img
                        src={`${base_url}/assets/img/customer-img.png`}
                        width="50px"
                        class="img-fluid rounded-circle me-3"
                      />
                      <span>Julia Parker</span>
                    </div>
                    <div>
                      <a href="#" class="more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
              <div class="blog-card">
                <img
                  src={`${base_url}/assets/img/blog-img1.png`}
                  width="100%"
                  class="img-fluid"
                />
                <div class="blog-card-inner">
                  <span class="date">Thu, December 12</span>
                  <h3>
                    <a href="#">
                      Our Project report is accepted by all banks and NBFCs
                      across India
                    </a>
                  </h3>
                  <div class="d-flex align-items-center justify-content-between">
                    <div class="d-flex align-items-center">
                      <img
                        src={`${base_url}/assets/img/customer-img.png`}
                        width="50px"
                        class="img-fluid rounded-circle me-3"
                      />
                      <span>Julia Parker</span>
                    </div>
                    <div>
                      <a href="#" class="more">
                        Read More
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 m-auto custom-pagination">
              <nav aria-label="...">
                <ul class="pagination">
                  <li class="page-item disabled">
                    <a class="page-link">
                      <i class="fa fa-angle-left"></i> Prev
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">
                      1
                    </a>
                  </li>
                  <li class="page-item" aria-current="page">
                    <a class="page-link" href="#">
                      2
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      3
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      ...
                    </a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">
                      10
                    </a>
                  </li>
                  <li class="page-item next">
                    <a class="page-link" href="#">
                      Next <i class="fa fa-angle-right"></i>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Blog;
