import React from "react";
import { base_url } from "../../../basic";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const OurBlog = () => {


    return(
        <section id="blog-section">
        <div class="container-fluid">
           <div class="blog-heading">
              <h2>Our <span>Blogs</span></h2>
           </div>
           <div class="d-flex align-items-center justify-content-between" id="blog-slider">
           <OwlCarousel
              margin={20}
                items={3}
                autoplay={true}
                loop={true}
                autoplayHoverPause={true}
                autoplayTimeout={5000}
                autoplaySpeed={5000}
                nav={false}

                x// responsive={{
                //   0: { items: 3, dots: false },

                //   600: { items: 4, dots: false },

                //   1000: { items: 5, loop: true },
                // }}
              >
                 <div class="item">
                    <div class="blog-card">
                       <img src={`${base_url}/assets/img/blog-img1.png`} class="img-fluid"/>
                       <div class="blog-card-inner">
                          <span class="date">Thu, December 12</span>
                          <h3>Our Project report is accepted by all banks and NBFCs across India</h3>
                          <div class="d-flex align-items-center justify-content-between">
                             <div class="d-flex align-items-center">
                                <img src="img/customer-img.png" class="img-fluid rounded-circle me-3"/><span>Julia Parker</span>
                             </div>
                             <div><a href="#">Read More</a></div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="blog-card">
                       <img src={`${base_url}/assets/img/blog-img1.png`} class="img-fluid"/>
                       <div class="blog-card-inner">
                          <span class="date">Thu, December 12</span>
                          <h3>Our Project report is accepted by all banks and NBFCs across India</h3>
                          <div class="d-flex align-items-center justify-content-between">
                             <div class="d-flex align-items-center">
                                <img src={`${base_url}/assets/img/customer-img.png`} class="img-fluid rounded-circle me-3"/><span>Julia Parker</span>
                             </div>
                             <div><a href="#">Read More</a></div>
                          </div>
                       </div>
                    </div>
                 </div>
                 <div class="item">
                    <div class="blog-card">
                       <img src={`${base_url}/assets/img/blog-img1.png`} class="img-fluid"/>
                       <div class="blog-card-inner">
                          <span class="date">Thu, December 12</span>
                          <h3>Our Project report is accepted by all banks and NBFCs across India</h3>
                          <div class="d-flex align-items-center justify-content-between">
                             <div class="d-flex align-items-center">
                                <img src={`${base_url}/assets/img/customer-img.png`} class="img-fluid rounded-circle me-3"/><span>Julia Parker</span>
                             </div>
                             <div><a href="#">Read More</a></div>
                          </div>
                       </div>
                    </div>
                 </div>
             </OwlCarousel>
           </div>
        </div>
     </section>
    );

};

export default OurBlog;