import { createSlice } from '@reduxjs/toolkit';
export const userSlice = createSlice({
    name:"user",
    initialState:{
      user:null,
      page_id:null
    },
    reducers:{
      login:(state,action)=>{
        state.user=action.payload;
      },
      logout:(state)=>{
        state.user=null;        
      },
      page_id:(state,action)=>{
        state.page_id=action.payload;
      }
    },
});
export const { login,logout,page_id } = userSlice.actions;
export const selectUser=(state)=>state.user.user;
export const selectPage_id=(state)=>state.user.page_id;
export default userSlice.reducer;