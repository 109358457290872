import React from "react";
import { Navigate } from "react-router-dom";


// Import Authentication pages
import Login from "../pages/Authentication/Login";
import SignUp from "../pages/Authentication/SignUp";
import Home from "../pages/Modules/Home";
import AboutUs from "../pages/Modules/AboutUs";
import Pricing from "../pages/Modules/Pricing,";
import Report from "../pages/Modules/Reports";
import ContactUs from "../pages/Modules/ContactUs";
import Blog from "../pages/Modules/Blog";


const publicRoutes = [

  { path: "/", component: <Home /> },

  { path: "/login", component: <Login /> },

  { path: "/register", component: <SignUp /> },

  { path: "/about-us", component: <AboutUs /> },

  { path: "/pricing", component: <Pricing  /> },

  { path: "/report", component: <Report /> },

  { path: "/contact-us", component: <ContactUs /> },

  { path: "/blog", component: <Blog /> },


//   { path: "/*", component: <ErrorPage /> },
];    

const authProtectedRoutes = [
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "",
    exact: true,
    component: <Navigate to="" />,
  },
]; 

export { publicRoutes, authProtectedRoutes };