import React, { useCallback, useEffect } from 'react';
import Header from './Header';
import Footer from './Footer';

// import Components
// import Sidebar from './Sidebar';


const Layout = props => {

    return (
        <React.Fragment>
          <div id="layout-wrapper">
            {/* <ScrollToTop/> */}
           <Header />   
            <div className="main-content">{props.children}</div>
            <Footer />  
          </div>
        </React.Fragment>
      );
};

export default Layout;