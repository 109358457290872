import React from "react";
import { base_url } from "../../../basic";

const ProfessionalTeam = () => {
    return(
<section id="team-section">
         <div class="container-fluid">
            <div class="team-heading">
               <h2>Here is our <span>professional team</span></h2>
            </div>
            <div class="row">
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-1.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Manpreet Singh</h3>
                        <p>Founder</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-2.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Rahdu M. Barrk</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-3.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Joana K. Soleir</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
               <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
                  <div class="team-card">
                     <img src={`${base_url}/assets/img/team-4.png`} class="img-fluid"/>
                     <div class="team-info">
                        <h3>Marc P. Dursk</h3>
                        <p>Operator</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </section>
    );
};

export default ProfessionalTeam;