import React from "react";
import Clients from "./SubModules/Clients";
import Acheived from "./SubModules/Achieved";
import Testimonials from "./SubModules/Testimonials";
import ProfessionalTeam from "./SubModules/ProfessionalTeam";
import OurBlogs from "./SubModules/OurBlogs";
import Faq from "./SubModules/Faq";
import OurBlog from "./SubModules/OurBlog";

const Pricing = () => {
  return (
    <div>
      <section id="about-banner-section">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="about-text">
                <span class="line me-3">Pricing </span>
                <span>Enterfin Loan</span>
                <h1>
                  Lorem Lpsum Has Been The <span>Industry's Standard</span>
                </h1>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Clients />

      <section id="pricing-section">
        <div class="container-fluid">
          <div class="pricing-heading">
            <h2>
              Unlimited Edits &{" "}
              <span>
                Unlimited
                <br /> Downloads
              </span>
            </h2>
            <p>Our report is accepted by all banks in India</p>
          </div>
          <ul class="nav nav-pills" id="pills-tab" role="tablist">
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-home"
                type="button"
                role="tab"
                aria-controls="pills-home"
                aria-selected="true"
              >
                For Individual
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#pills-profile"
                type="button"
                role="tab"
                aria-controls="pills-profile"
                aria-selected="false"
              >
                For Finance Professionals
              </button>
            </li>
          </ul>
          <div class="tab-content" id="pills-tabContent">
            <div
              class="tab-pane fade show active"
              id="pills-home"
              role="tabpanel"
              aria-labelledby="pills-home-tab"
              tabindex="0"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-20">
                  <div class="pricing-card">
                    <h3>Enterfin Lite</h3>
                    <span>₹499</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                  <div class="pricing-card active">
                    <h3>Enterfin Pro</h3>
                    <span>₹999</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-20">
                  <div class="pricing-card">
                    <h3>Enterfin CMA</h3>
                    <span>₹1500</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="tab-pane fade"
              id="pills-profile"
              role="tabpanel"
              aria-labelledby="pills-profile-tab"
              tabindex="0"
            >
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-20">
                  <div class="pricing-card">
                    <h3>Enterfin Lite</h3>
                    <span>₹499</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                  <div class="pricing-card active">
                    <h3>Enterfin Pro</h3>
                    <span>₹999</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
                <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4 mt-20">
                  <div class="pricing-card">
                    <h3>Enterfin CMA</h3>
                    <span>₹1500</span>
                    <p>
                      Ideal for individuals who need quick access for basic
                      features.
                    </p>
                    <ul>
                      <h4>Includes:</h4>
                      <li>One time fee</li>
                      <li>Unlimited edits</li>
                      <li>Unlimited downloads</li>
                      <li>Up to 3 years of projections</li>
                      <li>Up to 7 pages</li>
                      <li>
                        Project loans, Term loans and for increase working
                        capital limits
                      </li>
                    </ul>
                    <button type="button" class="btn started">
                      Get Started Now
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Acheived />

      <Testimonials />

      <ProfessionalTeam />

      <OurBlog />

      <Faq /> 
    </div>
  );
};
export default Pricing;
