import React, { useEffect } from 'react';
import { ToastContainer, toast } from "react-toastify";
import './App.css';
import ScrollToTop from './layout/scrollToTop';
import Index from './Routes/index'


function App() {
  return (
    <React.Fragment >
    <ScrollToTop/>
    <ToastContainer />
    <Index/>
  </React.Fragment>
  );
}

export default App;
