import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectUser } from "../store/userSlice";
import { base_url } from "../basic";
// import { base_url } from "../basic";
// import { getRequest } from "../helpers/api_helpers";

const Footer = () => {
  const user = useSelector(selectUser);
  const [logo, setLogo] = useState("");
  const [data, setData] = useState();
  const [menu, setMenu] = useState([]);

  useEffect(() => {
    // websitelogo();
  }, []);

  // const websitelogo = async () => {
  //   try {
  //     const logo = await getRequest({ url: "web/get-company-profile" });
  //     const weblogo = JSON.parse(JSON.stringify(logo));
  //     console.log(weblogo, "doodlel");
  //     if (weblogo.status == 200) {
  //       setData(weblogo.data);
  //       console.log(weblogo, "weblogo");
  //       setLogo(weblogo.data.footer_logo);
  //       localStorage.setItem("logo_url", weblogo.data.logo);
  //     }
  //   } catch (e) {
  //     return false;
  //   }
  // };

  return (
    <footer>
      <div class="container-fluid">
        <div class="row subscribe-main">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-8">
            <div class="subscribe">
              <h2>
                Subscribe To Our Newsletter <br />
                For Latest Updates
              </h2>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div class="subscribe-input input-group">
              <input
                type="text"
                class="form-control"
                placeholder="Email Address..."
                aria-label="Recipient's username"
                aria-describedby="basic-addon2"
              />
              <span class="input-group-text" id="basic-addon2">
                Subscribe{" "}
                <img
                  src={`${base_url}/assets/img/right-arrow.png`}
                  width="29px"
                  class="img-fluid ms-2"
                />
              </span>
            </div>
          </div>
        </div>
        <div class="row footer-main">
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div class="footer-logo">
              <img src={`${base_url}/assets/img/logo.png`} class="img-fluid" width="212px" />
              <p>
                Lorem Ipsum has been the industry's standard dummy text ever
                since the 1500s, when an unknown printer.
              </p>
              <div class="icon-part mt-4">
                <div class="me-3">
                  <img src={`${base_url}/assets/img/call-icon.png`} class="img-fluid" width="46px" />
                </div>
                <div>
                  <p>+91 1234567890</p>
                  <p>+91 1234567890</p>
                </div>
              </div>
              <div class="icon-part">
                <div class="me-3">
                  <img
                    src={`${base_url}/assets/img/message-icon.png`}
                    class="img-fluid"
                    width="46px"
                  />
                </div>
                <div>
                  <p>xyx.enterfin@gmail.com</p>
                  <p>abc.enterfin@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-2">
            <div class="footer-link">
              <h3>Our Links</h3>
              <ul>
                <li>
                  <a href="#">About Us</a>
                </li>
                <li>
                  <a href="#">Reviews</a>
                </li>
                <li>
                  <a href="#">Blogs</a>
                </li>
                <li>
                  <a href="#">Career</a>
                </li>
                <li>
                  <a href="#">Terms & Conditions</a>
                </li>
                <li>
                  <a href="#">Help</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-3">
            <div class="footer-link">
              <h3>Our Offices</h3>
              <div class="d-flex align-items-start">
                <img
                  src={`${base_url}/assets/img/location-icon.png`}
                  class="img-fluid me-3"
                  width="46px"
                />
                <p>
                  Ground & 3rd Floor, B, 78, Block B, Sector 60, Noida, Uttar
                  Pradesh 201301
                </p>
              </div>
              <div class="d-flex align-items-start">
                <img
                  src={`${base_url}/assets/img/location-icon.png`}
                  class="img-fluid me-3"
                  width="46px"
                />
                <p>
                  Ground & 3rd Floor, B, 78, Block B, Sector 60, Noida, Uttar
                  Pradesh 201301
                </p>
              </div>
              <div class="d-flex align-items-start">
                <img
                  src={`${base_url}/assets/img/location-icon.png`}
                  class="img-fluid me-3"
                  width="46px"
                />
                <p>
                  Ground & 3rd Floor, B, 78, Block B, Sector 60, Noida, Uttar
                  Pradesh 201301
                </p>
              </div>
            </div>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-12 col-lg-4">
            <div class="comments">
              <img
                src={`${base_url}/assets/img/comment-icon.png`}
                class="img-fluid me-3"
                width="74px"
              />
              <div>
                <p>Need Free Consultation?</p>
                <h4>
                  <a href="#">Book Schedule</a>
                </h4>
              </div>
            </div>
            <div class="social-links">
              <a href="#" class="me-1">
                <img
                  src={`${base_url}/assets/img/social-icon1.png`}
                  class="img-fluid"
                  width="40px"
                />
              </a>
              <a href="#" class="me-1">
                <img
                  src={`${base_url}/assets/img/social-icon2.png`}
                  class="img-fluid"
                  width="40px"
                />
              </a>
              <a href="#" class="me-1">
                <img
                  src={`${base_url}/assets/img/social-icon3.png`}
                  class="img-fluid"
                  width="40px"
                />
              </a>
              <a href="#">
                <img
                  src={`${base_url}/assets/img/social-icon4.png`}
                  class="img-fluid"
                  width="40px"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <section class="copyright">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="policy-links">
                <ul>
                  <li>
                    <a href="#">Privacy Policy</a>
                  </li>
                  <li>
                    <a href="#">Terms Of Service</a>
                  </li>
                  <li>
                    <a href="#">Legal</a>
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <div class="copyright-text">
                <p>© 2023 Enterfin Inc, USA . All Rights Reserved.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};

export default Footer;
