import React, { useEffect, useState } from "react";
import { base_url } from "../../../basic";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getRequest } from "../../../helpers/api_helpers";

const Clients = () => {
  const [data, setData] = useState(null);

  const loadPagData = async () => {
    try {
      getRequest({ url: "/web/get-sponsor" }).then((pages) => {
        const js_view = JSON.parse(JSON.stringify(pages));
        if (js_view.status == 200) {
          console.log(js_view, "hiiiiii");
          setData(js_view.data);
        }
      });
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    loadPagData();
  }, []);

  console.log(data, "mydata");

  return (
    <section id="client-section">
      <div class="container-fluid">
        <div
          class="d-flex align-items-center justify-content-between"
          id="client-slider"
        >
          <OwlCarousel
            margin={6}
            items={5}
            autoplay={true}
            loop={true}
            autoplayHoverPause={true}
            autoplayTimeout={2000}
            autoplaySpeed={3000}
            nav={false}
            responsive={{
              0: { items: 3, dots: false },

              600: { items: 4, dots: false },

              1000: { items: 5, loop: true },
            }}
          >
            {data?.map((item, idx) => (
              <>
                <div class="item" key={item.id}>
                <div class="client-logo">
                <img
                  src={item?.image}
                  class="img-fluid"
                />
              </div>
                </div>
              </>
            ))}

            {/* <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo1.png`}
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo2.png`}
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo3.png`}
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo4.png`}
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo5.png`}
                  class="img-fluid"
                />
              </div>
            </div>
            <div class="item">
              <div class="client-logo">
                <img
                  src={`${base_url}/assets/img/client-logo6.png`}
                  class="img-fluid"
                />
              </div>
            </div> */}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Clients;
