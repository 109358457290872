import React, { useEffect, useState } from "react";
import { base_url } from "../../../basic";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { getRequest } from "../../../helpers/api_helpers";

const Testimonials = () => {
  const [data, setData] = useState(null);

  const loadPagData = async () => {
    try {
      getRequest({ url: "/web/get-web-testimonial" }).then((pages) => {
        const js_view = JSON.parse(JSON.stringify(pages));
        if (js_view.status == 200) {
          console.log(js_view, "hiiiiii");
          setData(js_view.data);
        }
      });
    } catch (e) {
      return false;
    }
  };

  useEffect(() => {
    loadPagData();
  }, []);

  console.log(data,"mydata")

  return (
    <section id="testimonial-section">
      <div class="container-fluid">
        <div class="testimonial-heading">
          <h2>
            Our <span>Testimonials</span>
          </h2>
        </div>
        <div
          class="d-flex align-items-center justify-content-between"
          id="test-slider"
        >
          <OwlCarousel
            margin={20}
            items={3}
            autoplay={true}
            loop={true}
            autoplayHoverPause={true}
            autoplayTimeout={5000}
            autoplaySpeed={5000}
            nav={false}
            x // responsive={{
            //   0: { items: 3, dots: false },

            //   600: { items: 4, dots: false },

            //   1000: { items: 5, loop: true },
            // }}
          >
            {data?.map((item, idx) => (
              <>
                <div class="item" key={item.id} >
                  <div class="test-card">
                    <div class="w-20">
                      <img
                        src={item?.image}
                        class="img-fluid rounded-circle"
                      />
                    </div>
                    <div class="w-80">
                      <p>
                       {item?.city_country}
                      </p>
                      <h3>{item?.name} </h3>
                      <span>{item?.url}</span>
                    </div>
                  </div>
                </div>
              </>
            ))}
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;
