import React, { useEffect, useState } from "react";
import Clients from "./SubModules/Clients";
import { base_url } from "../../basic";
import Acheived from "./SubModules/Achieved";
import ProfessionalTeam from "./SubModules/ProfessionalTeam";
import Faq from "./SubModules/Faq";
import { getRequest } from "../../helpers/api_helpers";


const AboutUs = () => {

    const [pageContent, setPageContent] = useState({description:null, short:null, slider:null});

    const {description,short,slider} = pageContent;

    useEffect(()=>{
        let pathname = window.location.pathname;
    let updated_path = pathname.split("/")[1];
        loadContent(updated_path);

    },[])


    const loadContent = async (path) => {
        try {
        //   setLoader(true);
          let data = {
            slug : `${path}`,
          }
          getRequest({
            url: "web/get-page-data-by-slug",params:data,
          }).then((plan_data) => {
            const pricing_plan = JSON.parse(JSON.stringify(plan_data));
            console.log(plan_data.data, "plan_data");
    
            console.log(pricing_plan.data, "pricing_plan.data");
    
            if (pricing_plan.status) {
             setPageContent({...pageContent, description:pricing_plan?.data?.description, short:pricing_plan?.data?.short_description,slider:pricing_plan?.data?.page_slider_description})
            }
           
          });
        } catch (e) {
        //   setLoader(false);
          return null;
        }
      };


return(
<div>
{/* <section id="about-banner-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="about-text">
          <span class="line me-3">About </span><span>Enterfin Loan</span>
          <h1>Lorem Lpsum Has Been The <span>Industry's Standard</span></h1>
        </div>
      </div>
    </div>
  </div>
</section> */}

<div dangerouslySetInnerHTML={{ __html: short }}></div>

<Clients/>

<div dangerouslySetInnerHTML={{ __html: description }}></div>


{/* <section id="about-us-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="about-us-text">
          <h2>About Lorem Ipsum <span>has been the industry's.</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard </p>
          <div class="row">
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <ul>
                <li>Lorem Ipsum Book</li>
                <li>Where Does It Come From</li>
                <li>Lorem Ipsum Book</li>
              </ul>
            </div>
            <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
              <ul>
                <li>Many Variations Passages</li>
                <li>Lorem Ipsum Book</li>
                <li>Chunks As Necessary</li>
              </ul>
            </div>
          </div>
          <button type="button" class="btn report">Build Project Report</button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="about-us-img">
          <img src={`${base_url}/assets/img/about-us.png`} class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="our-mission-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 pr-94">
        <div class="our-mission">
          <h2><img src={`${base_url}/assets/img/mission-icon.png`} class="img-fluid" width="51px"/> Our <span>Mission</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make.</p>
          <ul>
            <li>the other hand, we denounce with righteous hand indignation</li>
            <li>dislike men who are so beguiled and demoralized beguiled men</li>
            <li>the charms of pleasure of the moment dislike men who are so beguiled and demoralized</li>
            <li>Nam libero tempore, cum soluta nobis est eligendi nobis est eligendi</li>
          </ul>
          <button type="button" class="btn report">Build Project Report</button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6 pl-94">
        <div class="our-vision">
          <h2><img src={`${base_url}/assets/img/vision-icon.png`} class="img-fluid" width="51px"/> Our <span>Vision</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make.</p>
          <ul>
            <li>the other hand, we denounce with righteous indignation</li>
            <li>dislike men who are so beguiled and demoralized</li>
            <li>the charms of pleasure of the moment</li>
            <li>Nam libero tempore, cum soluta nobis est eligendi</li>
            <li>he rejects pleasures to secure other greater pleasures</li>
          </ul>
          <button type="button" class="btn report">Build Project Report</button>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="who-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="who-text">
          <h2>Who Are <span>We?</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type.</p>
          <ul>
            <li>the other hand, we denounce with righteous indignation</li>
            <li>dislike men who are so beguiled and demoralized</li>
            <li>the charms of pleasure of the moment</li>
            <li>Nam libero tempore, cum soluta nobis est eligendi</li>
            <li>he rejects pleasures to secure other greater pleasures</li>
          </ul>
          <button type="button" class="btn report">Build Project Report</button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="who-img">
          <img src={`${base_url}/assets/img/who-img.png`} class="img-fluid"/>
        </div>
      </div>
    </div>
  </div>
</section> */}

<Acheived/>

{/* <section id="work-section">
  <div class="container-fluid">
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-6">
        <div class="work-text">
          <h2>How It <span>Works?</span></h2>
          <p>Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries,Lorem Ipsum has been the industry.</p>
          <div><img src={`${base_url}/assets/img/small-customer-icon.png`} class="img-fluid me-3" width="100px"/><span>+1k Customers</span></div>
          <button type="button" class="btn report">Build Project Report</button>
        </div>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-5 m-auto">
        <div class="work-details">
          <div class="w-20">
            <img src={`${base_url}/assets/img/hand-icon-yellow.png`} class="img-fluid hover-hide" width="52px"/>
            <img src={`${base_url}/assets/img/hand-icon-white.png`} class="img-fluid hover-show" width="52px"/>
          </div>
          <div class="w-80">
            <h3>Transparent Solutions</h3>
            <p>Our Project report is accepted by all banks and 
            NBFCs across India Our Project report is accepted by all banks and NBFCs across India</p>
          </div>
        </div>
        <div class="work-details">
          <div class="w-20">
            <img src={`${base_url}/assets/img/hand-icon-yellow.png`} class="img-fluid hover-hide" width="52px"/>
            <img src={`${base_url}/assets/img/hand-icon-white.png`} class="img-fluid hover-show" width="52px"/>
          </div>
          <div class="w-80">
            <h3>Transparent Solutions</h3>
            <p>Our Project report is accepted by all banks and 
            NBFCs across India Our Project report is accepted by all banks and NBFCs across India</p>
          </div>
        </div>
        <div class="work-details">
          <div class="w-20">
            <img src={`${base_url}/assets/img/hand-icon-yellow.png`} class="img-fluid hover-hide" width="52px"/>
            <img src={`${base_url}/assets/img/hand-icon-white.png`} class="img-fluid hover-show" width="52px"/>
          </div>
          <div class="w-80">
            <h3>Transparent Solutions</h3>
            <p>Our Project report is accepted by all banks and 
            NBFCs across India Our Project report is accepted by all banks and NBFCs across India</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> */}

<ProfessionalTeam/>

<Faq/>

</div>
);

};

export default AboutUs;